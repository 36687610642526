




















































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'Chem51LBProject3OIProcedureQualitativeObservations',
  components: {TableInput},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        rows: [{procedure: '', observations: ''}],
      },
      attachments: [] as File[],
      columns: [
        {label: 'Procedure', component: STextarea, prop: 'procedure'},
        {label: 'Observations', component: STextarea, prop: 'observations'},
      ],
    };
  },
  methods: {},
  computed: {
    attachments(): File[] {
      return [...this.attachments];
    },
  },
});
